import React from "react";
import axios from "axios";

export default class Presentation extends React.Component {
  state = {
    infos: [],
  };

  componentDidMount() {
    axios.get(`https://contenu.cartopodes.ch//wp-json/wp/v2/cartopodes`).then((res) => {
      const infos = res.data;
      this.setState({ infos });
    });
  }
  render() {
    return (
      <div className="presentation">
        <div className="pres-text">
          {this.state.infos[0] && <h1 dangerouslySetInnerHTML={{ __html: this.state.infos[0].title.rendered }} />}
          {this.state.infos[0] && <div dangerouslySetInnerHTML={{ __html: this.state.infos[0].content.rendered }} />}
        </div>
      </div>
    );
  }
}
