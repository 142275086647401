import "../styles/app.scss";
import HomeCanvas from "../components/HomeCanvas";
import Presentation from "../components/Presentation";
import { useState } from "react";
import Close from "../components/Close";

function App() {
  const [about, setAbout] = useState(false);

  let aboutClass = "not-visible";

  if (about) {
    aboutClass = "visible";
  } else {
    aboutClass = "not-visible";
  }

  return (
    <div className="App">
      <HomeCanvas setAbout={setAbout} />
      <div className={aboutClass + " about-general"}>{<Presentation className={aboutClass} />}</div>
      <div className="toggle" onClick={() => setAbout((state) => !state)}>
        {/* {!about && <h1>Cartopodes</h1>} */}
        {about && <Close />}
      </div>
    </div>
  );
}

export default App;
