import "../styles/app.scss";
import { Canvas } from "@react-three/fiber";
import React, { useEffect, Suspense, useState } from "react";
import Box from "./BoxEpisode";
import { OrbitControls, Text3D } from "@react-three/drei";
import * as font3D from "../fonts/changa.json";
import IceberCube from "./IcebergCubeModel";
import VesuveCube from "./VesuveCubeModel";
import HomeModel from "./HomeModel";
import { isMobile } from "react-device-detect";
import LoaderSpin from "../components/Loader";
import MondayCube from "./Episode8Cube";
import SpringCube from "./Episode9Cube";

let mobile = false;
if (isMobile) {
  mobile = true;
}
console.log(
  "%cwebsite by Chadi Messmer %chttp://www.chadimessmer.ch",
  "color: yellow; background: red; font-size: 30px; text-shadow: 2px 2px black",
  "color: red; background: yellow; font-size: 35px; text-shadow: 2px 2px black"
);
function HomeCanvas({ setAbout }) {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "grab";
  }, [hovered]);

  const isHovered = () => {
    setHovered(true);
    document.body.style.cursor = "pointer";
  };
  const notHovered = () => {
    setHovered(false);
    document.body.style.cursor = "grab";
  };

  return (
    <div className="canvas">
      <Canvas camera={{ position: [0, 18, 35] }}>
        {/* <Suspense fallback={<Loader />}> */}
        <directionalLight intensity={0.5} color="white" position={[-4, 10, 9]} />
        <directionalLight intensity={0.5} color="white" position={[1, 10, -9]} />
        {/* <Sky distance={450000} sunPosition={[0, 100, 0]} inclination={0} azimuth={0.25} /> */}
        <color attach="background" args={["#fcfff1"]} />
        <fog attach="fog" color="#fcfff1" near={1} far={70} />
        <OrbitControls maxDistance={45} enablePan={false} position={[0, 4, 4]} />

        <ambientLight intensity={0.5} />
        <group>
          <Box mobile={mobile} hovered={hovered} setHovered={setHovered} episode="1" episodeName=": LISIERES" />
        </group>
        <group>
          <Box mobile={mobile} hovered={hovered} setHovered={setHovered} episode="2" episodeName=": ILES" />
        </group>
        <group>
          <Box mobile={mobile} hovered={hovered} setHovered={setHovered} episode="3" episodeName=": ECLOSIONS" />
        </group>
        <group>
          <Box mobile={mobile} hovered={hovered} setHovered={setHovered} episode="4" episodeName=": PHONE CALL" />
        </group>
        <group>
          <Box mobile={mobile} hovered={hovered} setHovered={setHovered} episode="5" episodeName=": 29.02.2022" />
        </group>
        <group onClick={() => setAbout((state) => !state)} onPointerEnter={isHovered} onPointerOut={notHovered}>
          <Text3D size="4" font={font3D} position={[-15.8, 7, -13]} bevelEnabled bevelSize={0.08}>
            CARTOPODES
            <meshMatcapMaterial attach="material" color="red" />
          </Text3D>
        </group>
        <Suspense>
          <IceberCube mobile={mobile} hovered={hovered} />
        </Suspense>
        <Suspense>
          <MondayCube mobile={mobile} hovered={hovered} />
        </Suspense>
        <Suspense>
          <VesuveCube mobile={mobile} hovered={hovered} />
        </Suspense>
        <Suspense fallback={<LoaderSpin />}>
          <HomeModel scale={[3, 3, 3]} />
        </Suspense>
        <Suspense>
          <SpringCube scale={[8, 8, 8]} mobile={mobile} hovered={hovered} />
        </Suspense>
      </Canvas>
    </div>
  );
}

export default HomeCanvas;
