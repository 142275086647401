// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
import Close from "./Close";

function SingleEpisode({ episode, setVisible }) {
  return (
    <div className="presentation">
      <div onClick={() => setVisible(false)} className="toggle">
        <Close />
      </div>
      <div className="pres-text">
        <h1>{episode.acf.nom}</h1>
        {episode.acf.bio && <p>{episode.acf.bio}</p>}

        {/* <audio src={episode.acf.fichier_audio.url} /> */}
        <audio key={episode.id} controlsList="nodownload" controls>
          <source src={episode.acf.fichier_audio.url} type="audio/mpeg" />
        </audio>
        <br />
        {/* <AudioPlayer showSkipControls={false} autoPlay={false} src={episode.acf.fichier_audio.url} /> */}
        {episode.acf.site && (
          <a target="_blank" rel="noreferrer" href={episode.acf.site}>
            {episode.acf.site}
          </a>
        )}
      </div>
    </div>
  );
}

export default SingleEpisode;
