import React, { useState, useEffect } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useSpring, animated, config } from "@react-spring/three";
import { history } from "./History";
import { Html } from "@react-three/drei";
import ep1 from "../images/episode1.jpg";
import ep2 from "../images/episode2.jpg";
import ep3 from "../images/episode3.jpg";
import ep4 from "../images/episode4.jpg";
import ep5 from "../images/episode5.jpg";

function Box({ episode, hovered, setHovered, episodeName, mobile }) {
  let img;
  let position = [0, 0, 0];

  if (episode === "1") {
    img = ep1;
    position = [-1.5, 2.7, -8];
  } else if (episode === "2") {
    img = ep2;
    position = [8, 2.2, 8];
  } else if (episode === "3") {
    img = ep3;
    position = [-13, 2.9, -3];
  } else if (episode === "4") {
    img = ep4;
    position = [8, 2.9, -2];
  } else if (episode === "5") {
    img = ep5;
    position = [-3.5, 3.5, 7];
  }

  function MyRotatingBox() {
    const [active, setActive] = useState(false);
    const [hidden, set] = useState();

    useEffect(() => {
      document.body.style.cursor = active || hovered ? "pointer" : "grab";
    }, [active]);

    const isHovered = () => {
      setActive(true);
      // setHovered(true);
    };
    const notHovered = () => {
      setActive(false);
      // setHovered(false);
    };

    const { scale } = useSpring({
      scale: active ? 1.5 : 1,
      config: config.slow,
    });
    const myMesh = React.useRef();

    useFrame(({ clock }) => {
      const a = clock.getElapsedTime();
      myMesh.current.rotation.y = a * 0.5;
    });
    const image = useLoader(TextureLoader, img);

    const redirect = () => {
      if (episode === "1") {
        history.push("/episode1");
      } else if (episode === "2") {
        history.push("/episode2");
      } else if (episode === "3") {
        history.push("/episode3");
      } else if (episode === "4") {
        history.push("/episode4");
      } else if (episode === "5") {
        history.push("/episode5");
      }
    };

    return (
      <animated.mesh scale={scale} onClick={redirect} onPointerOver={isHovered} onPointerOut={notHovered} position={position} ref={myMesh}>
        <meshStandardMaterial map={image} />
        <boxGeometry args={[0.5, 0.5, 0.5]} />
        {(active || mobile) && (
          <Html
            wrapperClass="overlay-wrapper"
            occlude
            onOcclude={set}
            style={{
              transition: "all 0.5s",
              opacity: hidden ? 0 : 1,
            }}
            className="overlay"
            center={true}
            position={[0, 0.8, 0]}
            distanceFactor={10}
          >
            <div className="content">
              EPISODE {episode} {episodeName}
            </div>
          </Html>
        )}
      </animated.mesh>
    );
  }

  return <MyRotatingBox />;
}

export default Box;
