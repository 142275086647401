import Close from "./Close";
// import { Scrollbars } from "react-custom-scrollbars";

function PresentationEpisode({ presentation, setAbout }) {
  return (
    <div className="presentation">
      <div onClick={() => setAbout(false)} className="toggle">
        <Close />
      </div>
      <div className="pres-text">
        <h1>{presentation[0].title.rendered}</h1>
        <img src={presentation[0].acf.image.url} alt={presentation[0].acf.image.title} />
        <p dangerouslySetInnerHTML={{ __html: presentation[0].acf.presentation }} />
        <br />
      </div>
    </div>
  );
}

export default PresentationEpisode;
