import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE7 from "../model/ep7bis.glb";

export default function ModelSeven(props) {
  const { nodes, materials } = useGLTF(EPISODE7);
  return (
    <group {...props} dispose={null}>
      <group position={[-0.35, -0.97, -0.06]} rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials.brush_Light} />
          <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.brush_MatteHull} />
          {/* <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.brush_Smoke} /> */}
          <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.brush_ThickPaint} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE7);
