import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import HOMEMODEL from "../model/home.glb";

export default function HomeModel(props) {
  const group = useRef();
  const { nodes } = useGLTF(HOMEMODEL);
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_19.geometry} material={nodes.Object_19.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={nodes.Object_7.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={nodes.Object_5.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} material={nodes.Object_12.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={nodes.Object_10.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_17.geometry} material={nodes.Object_17.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_15.geometry} material={nodes.Object_15.material} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(HOMEMODEL);
