import { Text3D } from "@react-three/drei";
import * as font3D from "../fonts/changa.json";

function TitleEpisode({ title }) {
  return (
    <Text3D size="0.7" font={font3D} position={[-3.5, 3, -3.5]}>
      {title}
      <meshMatcapMaterial attach="material" color="red" />
    </Text3D>
  );
}

export default TitleEpisode;
