import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE8 from "../model/ep8.glb";

export default function ModelEight(props) {
  const { nodes, materials } = useGLTF(EPISODE8);
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials.brush_Spikes} />
      <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.brush_Petal} />
    </group>
  );
}

useGLTF.preload(EPISODE8);
