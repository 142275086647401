import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE1 from "../model/ep1.glb";

export default function ModelOne(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(EPISODE1);
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group rotation={[0, Math.PI, 0]}>
          <mesh geometry={nodes.Object_4.geometry} material={materials.brush_Paper} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE1);
