import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE2 from "../model/ep2.glb";
import { Float } from "@react-three/drei";

export default function ModelTwo(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(EPISODE2);
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -0.8, 0]}>
        <group rotation={[0, Math.PI, 0]}>
          <Float
            speed={0.15} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={0.001} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 1.01]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <mesh castShadow receiveShadow geometry={nodes.Object_13.geometry} material={materials.brush_OilPaint} />
            <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={nodes.Object_5.material} />
            <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={nodes.Object_8.material} />
            <mesh castShadow receiveShadow geometry={nodes.Object_11.geometry} material={nodes.Object_11.material} />
          </Float>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE2);
