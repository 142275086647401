import { Html, useProgress } from "@react-three/drei";
import React from "react";

function LoaderSpin() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div className="loader">
        <p>loading</p>
        <div className="loader-wrapper">
          <div className="load" style={{ height: "100%", width: progress + "%" }}></div>
        </div>
      </div>
    </Html>
  );
}

export default LoaderSpin;
