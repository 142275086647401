import { Canvas } from "@react-three/fiber";
import React, { useEffect, Suspense, useState } from "react";
import { useGLTF } from "@react-three/drei";
import VESUVECUBE from "../model/vesuve_cube.glb";
import { useFrame } from "@react-three/fiber";
import { useSpring, animated, config } from "@react-spring/three";
import { Html } from "@react-three/drei";
import { history } from "./History";
import { isMobile } from "react-device-detect";

let mobile = false;
if (isMobile) {
  mobile = true;
}

export default function Model(props, hovered) {
  function Vesuve() {
    const [active, setActive] = useState(false);
    const [hidden, set] = useState();
    useEffect(() => {
      document.body.style.cursor = active || hovered ? "pointer" : "grab";
    }, [active]);

    const isHovered = () => {
      setActive(true);
      // setHovered(true);
    };
    const notHovered = () => {
      setActive(false);
      // setHovered(false);
    };

    const { scale } = useSpring({
      scale: active ? 1.2 : 1,
      config: config.slow,
    });
    const myMesh = React.useRef();

    useFrame(({ clock }) => {
      const a = clock.getElapsedTime();
      myMesh.current.rotation.y = a * 0.5;
    });

    const redirect = () => {
      history.push("/episode7");
    };
    const { nodes, materials } = useGLTF(VESUVECUBE);
    return (
      <animated.mesh position={[-6, 2, -8]} onClick={redirect} onPointerOver={isHovered} onPointerOut={notHovered} scale={scale}>
        <group scale={[2, 2, 2]} ref={myMesh} {...props} dispose={null}>
          <group rotation={[-Math.PI / 2, 0, 0]}>
            <group rotation={[Math.PI / 2, 0, 0]}>
              <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_MatteHull} />
              <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.brush_MatteHull} />
              <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.brush_ThickPaint} />
            </group>
          </group>
        </group>
        {(active || mobile) && (
          <Html
            wrapperClass="overlay-wrapper"
            occlude
            onOcclude={set}
            style={{
              transition: "all 0.5s",
              opacity: hidden ? 0 : 1,
            }}
            className="overlay"
            center={true}
            position={[0, 2.8, 0]}
            distanceFactor={10}
          >
            <div className="content">EPISODE 7 : VESUVE</div>
          </Html>
        )}
      </animated.mesh>
    );
  }
  return <Vesuve />;
}

useGLTF.preload(VESUVECUBE);
