import "../styles/app.scss";
import { Canvas } from "@react-three/fiber";
import ModelThree from "../components/Episode3Model";
import { Suspense, useEffect, useState } from "react";
import { OrbitControls } from "@react-three/drei";

import axios from "axios";
import Sphere from "../components/Sphere";
import SingleEpisode from "../components/SingleEpisode";
// import baseURL from "../components/UrlArticles";
import { episodeUrl, episodeUrl3 } from "../components/UrlArticles";
import PresentationEpisode from "../components/PresentationEpisode";
import TitleEpisode from "../components/EpisodeTitle";

function Episode3() {
  const [post, setPost] = useState(null);
  const [episode, setEpisode] = useState(null);
  const [visible, setVisible] = useState(false);
  const [about, setAbout] = useState(false);
  const [presentation, setPresentation] = useState(null);
  const [titleHover, setTitleHover] = useState(false);

  let aboutClass = "not-visible";
  let presClass = "not-visible";

  if (visible) {
    aboutClass = "visible";
  } else {
    aboutClass = "not-visible";
  }

  if (about) {
    presClass = "visible";
  } else {
    presClass = "not-visible";
  }

  useEffect(() => {
    document.body.style.cursor = titleHover ? "pointer" : "auto";
  }, [titleHover]);

  const isHovered = () => {
    setTitleHover(true);
  };

  const notHovered = () => {
    setTitleHover(false);
  };

  useEffect(() => {
    axios.get(episodeUrl3).then((response) => {
      let thisEpisode = response.data;
      setPost(thisEpisode);
    });

    axios.get(episodeUrl).then((response) => {
      let thisPres = response.data.filter((obj) => {
        return obj.acf.numero === "3";
      });
      setPresentation(thisPres);
    });
  }, []);

  if (!post) return null;
  if (!presentation) return null;

  const showEpisode = (id) => {
    let thisEpisode = post.filter((obj) => {
      return obj.id === id;
    });
    setEpisode(thisEpisode);
    setVisible((state) => !state);
  };

  return (
    <div className="canvas">
      <Canvas camera={{ position: [0, 0, 5] }}>
        <directionalLight color="white" position={[-2, 20, 9]} />
        <directionalLight color="white" position={[1, 20, -5]} />

        <color attach="background" args={["#f5f5f5"]} />
        <fog attach="fog" color="#f5f5f5" near={1} far={15} />
        {post &&
          post.map((post) => (
            <group onClick={() => showEpisode(post.id)} key={post.id}>
              <Sphere nom={post.acf.nom} posX={post.acf.x} posY={post.acf.y} posZ={post.acf.z} />
            </group>
          ))}
        <OrbitControls enablePan={false} maxDistance={13} />
        <ambientLight intensity={0.5} />
        <Suspense fallback={null}>
          <ModelThree scale={[2, 2, 2]} />
        </Suspense>
        {presentation && (
          <group onPointerEnter={isHovered} onPointerOut={notHovered} onClick={() => setAbout((state) => !state)}>
            <TitleEpisode title={presentation[0].title.rendered} />
          </group>
        )}
      </Canvas>
      <div className={presClass + " about-general"}>
        <PresentationEpisode presentation={presentation} setAbout={setAbout} />
      </div>
      <div className={aboutClass + " about-general"}>
        {episode !== null && <SingleEpisode episode={episode[0]} visible={visible} setVisible={setVisible} />}
      </div>
    </div>
  );
}

export default Episode3;
