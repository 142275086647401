import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE9 from "../model/ep9.glb";

export default function Model(props) {
  const { nodes, materials } = useGLTF(EPISODE9);
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials.brush_Spikes} />
      <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.brush_Petal} />
    </group>
  );
}

useGLTF.preload(EPISODE9);
