import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import EP8CUBE from "../model/ep8_cube.glb";
import { useFrame } from "@react-three/fiber";
import { useSpring, animated, config } from "@react-spring/three";
import { Html } from "@react-three/drei";
import { history } from "./History";
import { isMobile } from "react-device-detect";

let mobile = false;
if (isMobile) {
  mobile = true;
}

function MondayCube(props, hovered) {
  function Monday() {
    const [active, setActive] = useState(false);
    const [hidden, set] = useState();
    useEffect(() => {
      document.body.style.cursor = active || hovered ? "pointer" : "grab";
    }, [active]);

    const isHovered = () => {
      setActive(true);
      // setHovered(true);
    };
    const notHovered = () => {
      setActive(false);
      // setHovered(false);
    };

    const { scale } = useSpring({
      scale: active ? 1.1 : 1,
      config: config.slow,
    });
    const myMesh = React.useRef();

    useFrame(({ clock }) => {
      const a = clock.getElapsedTime();
      myMesh.current.rotation.y = a * 0.5;
    });

    const redirect = () => {
      history.push("/episode8");
    };

    const group = useRef();
    const { nodes, materials } = useGLTF(EP8CUBE);
    return (
      <animated.mesh position={[13, -0.4, 0]} onClick={redirect} onPointerOver={isHovered} onPointerOut={notHovered} scale={scale}>
        <group ref={(group, myMesh)} {...props} dispose={null}>
          <group scale={[3.2, 3.2, 3.2]}>
            <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_Petal} />
            <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={materials.brush_Icing} />
          </group>
        </group>
        {(active || mobile) && (
          <Html
            wrapperClass="overlay-wrapper"
            occlude
            onOcclude={set}
            style={{
              transition: "all 0.5s",
              opacity: hidden ? 0 : 1,
            }}
            className="overlay"
            center={true}
            position={[0, 2.8, 0]}
            distanceFactor={10}
          >
            <div className="content">EPISODE 8 : One minute for blue monday</div>
          </Html>
        )}
      </animated.mesh>
    );
  }

  return <Monday />;
}

useGLTF.preload(EP8CUBE);

export default MondayCube;
