import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/app.scss";
import App from "./pages/App";
import Episode1 from "./pages/Episode1";
import Episode2 from "./pages/Episode2";
import Episode3 from "./pages/Episode3";
import Episode4 from "./pages/Episode4";
import Episode5 from "./pages/Episode5";
import Episode6 from "./pages/Episode6";
import Episode7 from "./pages/Episode7";
import Episode8 from "./pages/Episode8";
import Episode9 from "./pages/Episode9";

import { Route, Routes } from "react-router-dom";
import { history } from "./components/History";
import ThreeRouter from "./ThreeRouter";
// import { CSSTransition, TransitionGroup } from "./react-transition-group";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThreeRouter
      history={history}
      // component={({ history }) => {
      //   window.appHistory = history;
      // }}
    >
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/episode1" element={<Episode1 />} />
        <Route path="/episode2" element={<Episode2 />} />
        <Route path="/episode3" element={<Episode3 />} />
        <Route path="/episode4" element={<Episode4 />} />
        <Route path="/episode5" element={<Episode5 />} />
        <Route path="/episode6" element={<Episode6 />} />
        <Route path="/episode7" element={<Episode7 />} />
        <Route path="/episode8" element={<Episode8 />} />
        <Route path="/episode9" element={<Episode9 />} />
      </Routes>
    </ThreeRouter>
    {/* <App /> */}
  </React.StrictMode>
);
