const urlCarto = "https://contenu.cartopodes.ch//wp-json/wp/v2/";

const baseURL = urlCarto + "episodes/?per_page=100";

export default baseURL;

export const episodeUrl = urlCarto + "episode";

export const episodeUrl2 = urlCarto + "episode2/?per_page=100";

export const episodeUrl3 = urlCarto + "episode3/?per_page=100";

export const episodeUrl4 = urlCarto + "episode4/?per_page=100";

export const episodeUrl5 = urlCarto + "episode5/?per_page=100";

export const episodeUrl6 = urlCarto + "episode6/?per_page=100";

export const episodeUrl7 = urlCarto + "episode7/?per_page=100";

export const episodeUrl8 = urlCarto + "episode8/?per_page=100";

export const episodeUrl9 = urlCarto + "episode9/?per_page=100";
