import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE3 from "../model/ep3.glb";

export default function ModelThree(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(EPISODE3);
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -1.7, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_Icing} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE3);
