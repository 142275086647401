import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE4 from "../model/ep4.glb";

export default function ModelFour(props) {
  const group = useRef();
  const { nodes } = useGLTF(EPISODE4);
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[0, Math.PI / 2, 0]} position={[0.5, -0.68, 0]}>
        <group>
          <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={nodes.Object_4.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={nodes.Object_7.material} />
          <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={nodes.Object_10.material} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE4);
