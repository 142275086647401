import React, { useRef, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import ICEBERGCUBE from "../model/iceberg_cube.glb";
import { useFrame } from "@react-three/fiber";
import { useSpring, animated, config } from "@react-spring/three";
import { Html } from "@react-three/drei";
import { history } from "./History";
import { isMobile } from "react-device-detect";

let mobile = false;
if (isMobile) {
  mobile = true;
}

function IceberCube(props, hovered) {
  function IceBerg() {
    const [active, setActive] = useState(false);
    const [hidden, set] = useState();
    useEffect(() => {
      document.body.style.cursor = active || hovered ? "pointer" : "grab";
    }, [active]);

    const isHovered = () => {
      setActive(true);
      // setHovered(true);
    };
    const notHovered = () => {
      setActive(false);
      // setHovered(false);
    };

    const { scale } = useSpring({
      scale: active ? 1.5 : 1,
      config: config.slow,
    });
    const myMesh = React.useRef();

    useFrame(({ clock }) => {
      const a = clock.getElapsedTime();
      myMesh.current.rotation.y = a * 0.5;
    });

    const redirect = () => {
      history.push("/episode6");
    };

    const group = useRef();
    const { nodes, materials } = useGLTF(ICEBERGCUBE);
    return (
      <animated.mesh position={[-11, 3.7, 3]} onClick={redirect} onPointerOver={isHovered} onPointerOut={notHovered} scale={scale}>
        <group ref={(group, myMesh)} {...props} dispose={null}>
          <group scale={[2, 2, 2]}>
            <group>
              <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_Ink} />
              <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.brush_MatteHull} />
            </group>
          </group>
        </group>
        {(active || mobile) && (
          <Html
            wrapperClass="overlay-wrapper"
            occlude
            onOcclude={set}
            style={{
              transition: "all 0.5s",
              opacity: hidden ? 0 : 1,
            }}
            className="overlay"
            center={true}
            position={[0, 0.8, 0]}
            distanceFactor={10}
          >
            <div className="content">EPISODE 6 : ICEBERG</div>
          </Html>
        )}
      </animated.mesh>
    );
  }

  return <IceBerg />;
}

useGLTF.preload(ICEBERGCUBE);

export default IceberCube;
