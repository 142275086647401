import React, { useState, useEffect } from "react";
import { useSpring, animated, config } from "@react-spring/three";
import { Html } from "@react-three/drei";
import { Float } from "@react-three/drei";
import { isMobile } from "react-device-detect";

let mobile = false;
if (isMobile) {
  mobile = true;
}

function Sphere({ posX, posY, posZ, nom }) {
  const [hovered, setHovered] = useState(false);
  const [hidden, set] = useState();

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "grab";
  }, [hovered]);

  const isHovered = () => {
    setHovered(true);
  };
  const notHovered = () => {
    setHovered(false);
  };

  const { scale } = useSpring({
    scale: hovered ? 2 : 1,
    config: config.slow,
  });

  return (
    <Float
      speed={0.15} // Animation speed, defaults to 1
      rotationIntensity={1} // XYZ rotation intensity, defaults to 1
      floatIntensity={0.001} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
      floatingRange={[1, 1.01]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
    >
      <animated.mesh
        scale={scale}
        onPointerOver={isHovered}
        onPointerOut={notHovered}
        visible
        userData={{ test: "hello" }}
        position={[posX, posY, posZ]}
        castShadow
      >
        <sphereGeometry attach="geometry" args={[0.1, 26, 26]} />
        <meshMatcapMaterial attach="material" color="red" />
        {(hovered || mobile) && (
          <Html
            wrapperClass="overlay-wrapper"
            occlude
            onOcclude={set}
            style={{
              transition: "all 0.5s",
              opacity: hidden ? 0 : 1,
            }}
            className="overlay"
            center={true}
            position={[0, 0.3, 0]}
            distanceFactor={6}
          >
            <div className="content">{nom}</div>
          </Html>
        )}
      </animated.mesh>
    </Float>
  );
}

export default Sphere;
