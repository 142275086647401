import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE6 from "../model/ep6.glb";

export default function ModelSix(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(EPISODE6);
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, Math.PI / 2]} position={[0, -0.7, 1]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_9.geometry} material={materials.brush_Light} />
          <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_Ink} />
          <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={materials.brush_MatteHull} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE6);
