import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import EPISODE5 from "../model/ep5.glb";
import { Float } from "@react-three/drei";

export default function ModelFive(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(EPISODE5);
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.13, -1.45, 0]}>
        <group rotation={[0, Math.PI, 0]}>
          <Float
            speed={0.15} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={0.001} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 1.01]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <mesh castShadow receiveShadow geometry={nodes.Object_7.geometry} material={materials.brush_Flat} />
            <mesh castShadow receiveShadow geometry={nodes.Object_9.geometry} material={materials.brush_Ink} />
          </Float>
          <Float
            speed={0.45} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={0.01} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 1.01]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.brush_Icing} />
          </Float>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(EPISODE5);
